<template>
  <div id="kt_body" class="bg-body">
    <div class="d-flex flex-column flex-root" style="height: 100vh">
      <div class="
          d-flex
          flex-column flex-column-fluid
          bgi-position-y-bottom
          position-x-center
          bgi-no-repeat bgi-size-cover bgi-attachment-fixed
          backimage
        ">
        <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <router-link to="/login" class="mb-12">
            <img
              alt="Logo"
              class="h-45px"
              src="../assets/img/isbayi.png"
            />
          </router-link>
          <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <form class="form w-100">
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3">Giriş</h1>
                <!-- <div class="text-gray-400 fw-bold fs-4">
                  Yeni misiniz?
                  <router-link to="/register" class="link-primary fw-bolder">Bayi Kayıt Formu</router-link>
                </div> -->
              </div>
              <div class="form-floating mb-7">
                <input ref="email" v-model="email" @keypress.enter="giris($event)" type="email" name="email"
                  class="form-control" autocomplete="off" id="floatingInput" placeholder="name@example.com" />
                <label for="floatingInput">Email</label>
              </div>
              <div class="fv-row mb-10">
                <div class="form-floating mb-7">
                  <input ref="email" v-model="password" @keypress.enter="giris($event)" type="password" name="password"
                    class="form-control" autocomplete="off" id="floatingInput" placeholder="name@example.com" />
                  <label for="floatingInput">Şifre</label>
                </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-lg btn-primary w-100 mb-5" @click="signin">
                  <span class="indicator-label">Giriş Yap</span>
                  <span class="indicator-progress">Lütfen bekleyiniz...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
                <!--end::Submit button-->
                <!--begin::Separator-->
                <!-- <div class="text-center text-muted text-uppercase fw-bolder mb-5">ya da</div> -->
                <!--end::Separator-->
                <!--begin::Google link-->
                <!-- <a class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
								<img alt="Logo" src="../assets/media/svg/brand-logos/google-icon.svg" class="h-20px me-3" />Google ile Giriş Yap</a> -->
                <!--end::Google link-->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    
<script>
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    giris(e) {
      if (e.keyCode === 13) {
        this.signin();
      }
    },
    signin() {
      if (this.email == "" || this.password == "") {
        toastr.warning(
          "Giriş yapabilmek için lütfen boş alanları doldurunuz.",
          "Uyarı!"
        );
      } else {
        let params = {
          email: this.email,
          password: this.password,
        };
        this.$store.dispatch("verification", params);
      }
    },
  },
  mounted() {
    this.$refs.email.focus();
  },
};
</script>

<style>
.backimage {
  background-image: url(../assets/media/patterns/pattern-1.jpg);
}
</style>
<template>
  <div class="container-fluid">
    <div v-if="loading" class="">
      <section>
        <svg class="spinner" viewBox="0 0 16 18">
          <path class="path" fill="none" stroke-width="2"
            d="M7.21487 1.2868C7.88431 0.9044 8.73031 0.9044 9.39974 1.2868L9.40283 1.28856L14.4613 4.20761C15.1684 4.598 15.5746 5.33558 15.5746 6.11465V8.99996V11.8853C15.5746 12.6507 15.1632 13.3848 14.4617 13.7721L9.37973 16.7132C8.71029 17.0956 7.86428 17.0956 7.19485 16.7132L7.19088 16.7109L2.11279 13.772C1.40602 13.3816 1 12.6441 1 11.8653V8.98995V6.11465C1 5.31458 1.44381 4.59039 2.10827 4.21051L7.21487 1.2868Z" />
        </svg>
      </section>
    </div>
    <div v-else class="card">
      <div class="card-body p-lg-17">
        <div class="mb-18">
          <div class="mb-10">
            <div class="mb-15">

              <div class="card mb-5 mb-xxl-8">
                <div class="card-body pt-9 pb-0">
                  <div class="d-flex flex-wrap flex-sm-nowrap">
                    <div class="me-7 mb-4">
                      <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                        <img v-if="this.getUserData.user?.profile_picture == null || this.getUserData.user?.profile_picture == '[]' || this.getUserData.user?.profile_picture == []" src="/_assets/media/avatars/blank.png" alt="image" class="img-fluid shadow">
                        <img v-else :src="getImages(this.getUserData.user?.profile_picture)" alt="image" class="img-fluid shadow">
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div class="d-flex flex-column">
                          <div class="d-flex align-items-center mb-2">
                            <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">
                              {{ this.getUserData.user.name_basic }} {{ this.getUserData.user.surname }}
                            </a>
                          </div>
                          <div class="d-flex flex-wrap fw-semibold fs-5 pe-2">
                            <a href="#" class="d-flex align-items-center text-gray-700 text-hover-primary me-5 mb-2">
                              Kullanıcı ID : 646 687

                            </a>
                            <span class="ms-1" data-bs-toggle="tooltip" title="Kullanıcı ID gösterir.">
                              <i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                            </span>

                          </div>
                          <div class="d-flex flex-wrap fw-semibold fs-5 mb-4 pe-2">
                            <a href="#" class="d-flex align-items-center text-gray-700 text-hover-primary me-5 mb-2">
                              Hesap Durumu : <span class="ms-2 text-success">Aktif</span>
                            </a>
                          </div>
                        </div>
                        <div class="col-md-6 col-xxl-4">
                          <div class="d-flex flex-center flex-column">
                            <div class="fs-2 text-gray-800 fw-bold mb-3 d-flex">Müşteriler
                              <span class="ms-1" data-bs-toggle="tooltip"
                                title="Aktif / Pasif müşteri sayısını gösterir.">
                                <i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                              </span>
                            </div>
                            <div class="d-flex flex-center flex-wrap mb-5">
                              <div class="border border-dashed rounded min-w-90px py-2 px-3 mx-2 mb-3">
                                <div class="fs-3 fw-bold text-gray-700">{{ this.customersRight.all_records_count }}</div>
                                <div class="fw-semibold text-success">Aktif</div>
                              </div>
                              <div class="border border-dashed rounded min-w-90px py-2 px-3 mx-2 mb-3">
                                <div class="fs-3 fw-bold text-gray-700">{{ this.customersLeft.all_records_count }}</div>
                                <div class="fw-semibold text-danger">Pasif</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex flex-wrap flex-stack">
                        <div class="d-flex flex-column flex-grow-1 pe-8">
                          <div class="d-flex flex-wrap">
                            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                              <div class="d-flex align-items-center justify-content-center">
                                <div class="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value="4500"
                                  data-kt-countup-prefix="$" data-kt-initialized="1">- / -</div>
                              </div>
                              <div class="fw-semibold fs-6 text-gray-600">Bayi Puanı
                                <span class="ms-1" data-bs-toggle="tooltip" title="Bayi puanını gösterir.">
                                  <i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                                </span>
                              </div>
                            </div>
                            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                              <div class="d-flex align-items-center justify-content-center">
                                <div class="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value="80"
                                  data-kt-initialized="1">-</div>
                              </div>
                              <div class="fw-semibold fs-6 text-gray-600">Aylık Sıralama
                                <span class="ms-1" data-bs-toggle="tooltip" title="Aylık sıralamayı gösterir.">
                                  <i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                          <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                            <span class="fw-semibold fs-6 text-gray-600 d-flex">Eğitimler
                              <span class="ms-1" data-bs-toggle="tooltip" title="Eğitimleri gösterir.">
                                <i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                              </span>
                            </span>
                            <span class="fw-bold fs-6">1%</span>
                          </div>
                          <div class="h-5px mx-3 w-100 bg-light mb-3">
                            <div class="bg-success rounded h-5px" role="progressbar" style="width: 1%;" aria-valuenow="50"
                              aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-xl-12">
                <h3 class="card-title align-items-start flex-column d-flex">
                  <span class="card-label fw-bold text-dark mb-3 d-flex">Hızlı Erişim
                    <span class="ms-1" data-bs-toggle="tooltip" title="Platformlara hızlı ulaşmayı sağlar.">
                      <i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                    </span>
                  </span>
                </h3>
                <div class="col-xl-3 hover-elevate-up">
                  <a href="https://ishesap.com/" target="_blank">
                    <div class="card card-flush">
                      <div class="card-header pt-5 mb-6">
                        <h3 class="card-title align-items-start flex-column">
                          <div class="position-relative">
                            <img src="../assets/img/is-hesap.png" width="150" alt="image">
                          </div>
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-xl-3 hover-elevate-up">
                  <a href="https://ishesap.com/blog/" target="_blank">
                    <div class="card card-flush">
                      <div class="card-header pt-5 mb-6">
                        <h3 class="card-title align-items-start flex-column">
                          <div class="position-relative">
                            <img src="../assets/img/is-blog.png" width="150" alt="image">
                          </div>
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-xl-3 hover-elevate-up">
                  <a href="https://ishesap.com/docs/" target="_blank">
                    <div class="card card-flush">
                      <div class="card-header pt-5 mb-6">
                        <h3 class="card-title align-items-start flex-column">
                          <div class="position-relative">
                            <img src="../assets/img/is-docs.png" width="150" alt="image">
                          </div>
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-xl-3 hover-elevate-up">
                  <a href="https://www.hmb.gov.tr/" target="_blank">
                    <div class="card card-flush">
                      <div class="card-header pt-5 mb-6">
                        <h3 class="card-title align-items-start flex-column">
                          <div class="position-relative">
                            <img src="../assets/img/hmb.png" width="170" alt="image">
                          </div>
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import List from '@/components/List.vue'
import axios from "axios";
import {
  mapGetters
} from "vuex";

export default {
  components: { List },
  data() {
    return {
      loading: false,
      customersLeft: [],
      customersRight: [],
      notes: [],
      prices: [],
      nots_visible: {
        id: false,
        created_at: false,
        updated_at: false,
        own_id: false,
        user_id: false,
        kullanici_id: false,
        bayi_id: false,
        state: false,
      },

      table_refresh: 0,

      title: "",
      description: "",
      islem_tarihi: "",

      formatter: new Intl.NumberFormat('tr-TR', {
          //style: 'currency',
          currency: 'TRY',
          minimumFractionDigits: 2
      }),
    };
  },
  computed: {
    ...mapGetters(["getUserData"]),
  },
  methods: {
    getCustomerLeft() {
      this.loading = true;
      const dateNow =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) +
        "-" +
        (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate());
      let params = {
        page: 1,
        limit: 100000,
        column_array_id: this.getUserData.auths.tables["departments"].lists[0],
        column_array_id_query: this.getUserData.auths.tables["departments"].queries[0],
        sorts: {},
        filters: {
          son_kullanma_tarihi: {
            type: 2,
            guiType: "date",
            filter: dateNow,
          }
        }
      };
      axios
        .post(this.$store.getters.getToken + "/tables/departments", {
          "params": JSON.stringify(params)
        })
        .then((response) => {
          this.customersLeft = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomerRight() {
      this.loading = true;
      const dateNow =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) +
        "-" +
        (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate());
      let params = {
        page: 1,
        limit: 100000,
        column_array_id: this.getUserData.auths.tables["departments"].lists[0],
        column_array_id_query: this.getUserData.auths.tables["departments"].queries[0],
        sorts: {},
        filters: {
          son_kullanma_tarihi: {
            type: 3,
            guiType: "date",
            filter: dateNow,
          }
        }
      };
      axios
        .post(this.$store.getters.getToken + "/tables/departments", {
          "params": JSON.stringify(params)
        })
        .then((response) => {
          this.customersRight = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hideModal() {
      $("#noteModal").modal("hide")
    },
    getNotes() {
      axios.post(this.$store.getters.getToken + "/tables/bayi_bildirimler", {
        params: JSON.stringify({
          page: 1,
          limit: 30,
          sorts: {},
          filters: {},
          column_array_id: this.getUserData.auths.tables["bayi_bildirimler"].lists[0],
          column_array_id_query: this.getUserData.auths.tables["bayi_bildirimler"].queries[0],
        })
      }).then(res => {
        this.notes = res.data.data.records
      }).catch(err => {
        err;
        toastr.error("Notular yüklenirken bir hata oluştu.")
      })
    },
    controlNotes() {
      if (this.title == "" || this.description == "" || this.islem_tarihi == "") {
        toastr.error("Lütfen tüm alanları doldurunuz.")
        return false;
      } else this.saveNote();
    },
    saveNote() {
      let params = {
        title: this.title,
        description: this.description,
        islem_tarihi: this.islem_tarihi,
        bayi_id: this.getUserData.user.bayi_id,
        column_set_id: this.getUserData.auths.tables["bayi_bildirimler"].creates[0],
      }
      axios.post(this.$store.getters.getToken + '/tables/bayi_bildirimler/store', params).then(res => {
        if (res.data.data.message == 'success') {
          $("#noteModal").modal("hide")
          toastr.success("Notunuz başarıyla kaydedildi.")
          this.table_refresh += 1;
          this.getNotes();
        } else {
          toastr.error("Notunuz kaydedilirken bir hata oluştu.")
        }
      }).catch(err => {
        err;
        toastr.error("Notunuz kaydedilirken bir hata oluştu.")
      })
    },
    getPrices() {
      this.loading = true;
      axios.get("/bayigunler/" + this.getUserData.user.bayi_id).then((response) => {
        this.prices = response.data;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.getCustomerLeft();
    this.getCustomerRight();
    this.getNotes();
    this.getPrices();
  },
};
</script>

<style>
.border-2 {
  border: 2px solid #22abc3;
  padding: 0.8rem !important;
}

.col-xl-5 {
  font-weight: 400;
}

.btnCstm {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 10px !important;
}</style>